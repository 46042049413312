<template>
    <div>
        <h4>机构信息登记</h4>
        <el-divider></el-divider>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="first">
                <el-form :model="formData" label-width="150px" :rules="formDataRules" ref="formData" v-loading="listLoading">
                    <el-form-item label="培训机构名称:" prop="name">
                        <el-input style="width:766px;" v-model="formData.name" placeholder="请输入培训机构名称" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="培训机构类型:" prop="organType">
                        <el-select v-model="formData.organType" placeholder="请选择培训机构类型" clearable>
                            <el-option
                                v-for="item in organTypeArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="培训机构简介:">
                        <el-input style="width:766px;" v-model="formData.organIntroduce" placeholder="请输入培训机构简介" maxlength="30" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="联系人:" prop="contactPerson">
                        <el-input style="width:766px;" v-model="formData.contactPerson" placeholder="请输入联系人" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="联系人手机号:" prop="contactPersonPhoneNumber">
                        <el-input style="width:766px;" v-model="formData.contactPersonPhoneNumber" placeholder="请输入联系人手机号" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <div style="display: flex;margin-bottom: 20px;margin-left:103px;">
                        <div>
                            <span class="selecRegionStyle">省:</span>
                            <el-select @change="selectRowChange1" v-model="formData.region" placeholder="请选择" v-loading="listLoading12">
                                <template v-for="item in shengArr">
                                    <el-option
                                        :key="item.Id"
                                        :label="item.Name"
                                        :value="item.Id"
                                        :disabled='isDisable'>
                                    </el-option>
                                </template>
                            </el-select>
                        </div>
                        <div>
                            <span class="selecRegionStyle">市:</span>
                            <el-select @change="selectRowChange2" v-model="formData.region2" placeholder="请选择" v-loading="listLoading13">
                                <template v-for="item in shiArr">
                                    <el-option
                                        :key="item.Id"
                                        :label="item.Name"
                                        :value="item.Id"
                                        :disabled='isDisable'>
                                    </el-option>
                                </template>
                            </el-select>
                        </div>
                        <div>
                            <span class="selecRegionStyle">区/县:</span>
                            <el-select @change="selectRowChange3" v-model="formData.region3" placeholder="请选择" v-loading="listLoading14">
                                <template v-for="item in xianArr">
                                    <el-option
                                        :key="item.Id"
                                        :label="item.Name"
                                        :value="item.Id"
                                        :disabled='isDisable'>
                                    </el-option>
                                </template>
                            </el-select>
                        </div>
                    </div>
                    <el-form-item label="详细地址:" prop="address">
                        <el-input style="width:766px;" v-model="formData.address" placeholder="请输入详细地址" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="办学规模(人):" prop="scaleCount">
                        <el-input style="width:766px;" v-model="formData.scaleCount" placeholder="请输入办学规模" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="注册资本(万):" prop="registeredCapital">
                        <el-input style="width:766px;" v-model="formData.registeredCapital" placeholder="请输入注册资本" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="微信小程序ID:">
                        <el-input style="width:766px;" v-model="formData.wechatAppletID" placeholder="请输入微信小程序ID" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="微信小程序首页路径:">
                        <el-input style="width:766px;" v-model="formData.wechatAppletHomePageUrl" placeholder="请输入微信小程序首页路径" clearable :disabled='isDisable'></el-input>
                    </el-form-item>
                    <el-form-item label="是否启用:" prop="isEnable">
                        <el-select v-model="formData.isEnable" placeholder="请选择">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :disabled='isDisable'>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div style="width:65%;min-width:370px;">
                    <el-button @click="toSecondPage()" type="primary" style="margin: 0 auto;display: flex;" v-if='isDisable == false'>下一步</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="提供的培训服务" name="second" :disabled="canNextPage1">
            <!-- <el-tab-pane label="提供的培训服务" name="second"> -->
                <el-form :model="formData1" label-width="130px" :rules="formDataRules1" ref="formData1">
                    <el-form-item label="提供的培训服务:" prop="programIDs">
                        <el-tree
                            style="padding:10px 0;max-height:650px;overflow-y:auto;max-width:1000px;"
                            :data="dataTree"
                            show-checkbox
                            node-key="Value"
                            ref="tree"
                            default-expand-all
                            :expand-on-click-node="true"
                            :check-strictly="true"
                            :props="defaultProps"
                            v-loading="listLoading2"
                        >
                            <span class="custom-tree-node" slot-scope="{ node, data }">
                                <span>{{ node.label }}</span>
                                <span>
                                    <el-checkbox-group v-model="assignBtns">
                                        <el-checkbox v-for="btn in data.Buttons" :key="btn.ID"
                                            :label="btn.ID.toString()" :disabled='isDisable'>
                                            {{btn.Name}}
                                        </el-checkbox>
                                    </el-checkbox-group>
                                </span>
                            </span>
                        </el-tree>
                    </el-form-item>
                </el-form>
                <div style="width:65%;min-width: 700px;">
                    <el-button @click="toThirdPage()" type="primary" style="margin: 0 auto;display: flex;" v-if='isDisable == false'>下一步</el-button>
                </div>
            </el-tab-pane>
            <el-tab-pane label="上传资质照片" name="third" :disabled="canNextPage2">
            <!-- <el-tab-pane label="上传资质照片" name="third"> -->
                <el-form :model="formData2" label-width="150px" :rules="formDataRules2" ref="formData2" :inline='true'>
                    <el-form-item label="营业执照:" prop="businessLicenseImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success1"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="businessLicenseImgUrl1_2" :src="businessLicenseImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="办学许可证:" prop="schoolLicenceImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success2"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="schoolLicenceImgUrl1_2" :src="schoolLicenceImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <el-form :model="formData3" label-width="150px" :rules="formDataRules3" ref="formData3" :inline='true'>
                    <el-form-item label="前台外观照:" prop="receptionOneImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success3"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="receptionOneImgUrl1_2" :src="receptionOneImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label=" " prop="receptionTwoImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success4"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="receptionTwoImgUrl1_2" :src="receptionTwoImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <el-form :model="formData4" label-width="150px" :rules="formDataRules4" ref="formData4" :inline='true'>
                    <el-form-item label="理论学习场景照:" prop="classRoomOneImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success5"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="classRoomOneImgUrl1_2" :src="classRoomOneImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label=" " prop="classRoomTwoImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success6"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="classRoomTwoImgUrl1_2" :src="classRoomTwoImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <el-form :model="formData5" label-width="150px" :rules="formDataRules5" ref="formData5" :inline='true'>
                    <el-form-item label="实操学习场景照:" prop="operationOneImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success7"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="operationOneImgUrl1_2" :src="operationOneImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label=" " prop="operationTwoImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success8"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="operationTwoImgUrl1_2" :src="operationTwoImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <!-- <el-form :model="formData6" label-width="150px" :rules="formDataRules6" ref="formData6" :inline='true'>
                    <el-form-item label="学习人口二维码:" prop="lessonQRCodeImgUrl">
                        <el-upload
                        :action="uploadImagesUrl"
                        :headers="headers"
                        ref="upload"
                        :before-upload="beforeUpload"
                        :on-success="handle_success9"
                        :show-file-list="false"
                        list-type="picture-card"
                        >
                            <img v-if="lessonQRCodeImgUrl1_2" :src="lessonQRCodeImgUrl1_2" class="avatar" />
                            <i v-else class="el-icon-plus"></i>
                        </el-upload>
                    </el-form-item>
                </el-form> -->
                <div style="width:65%;min-width: 769px;">
                    <el-button @click="toSubmitData()" type="primary" style="margin: 0 auto;display: flex;" :loading="addLoading" v-if='isDisable == false'>提 交</el-button>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getAgencyInfo,addAgencyInfo,updateAgencyInfo,getAreaDictionaryList,getDataDictionaryList, getDeletedStaffsAuthoritySetting } from '../../api/api';
import api from '@/api'
// import city from '../../../util/city'
import { validPhone } from "../../../util/validate";
export default {
    data() {
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        return {
            // 当状态为1（审核通过时）,为禁用
            isDisable:false,
            options:[
                {label:'启用',value:true},
                {label:'禁用',value:false}
            ],
            organTypeArr:[
                {label:'线上授课',value:1},
                {label:'线下授课',value:2}
            ],
            activeName: 'first',
            uploadImagesUrl: '',
            needSubmitID: '',
            formData: {
                name: '',
                organType: '',
                organIntroduce: '',
                contactPerson: '',
                contactPersonPhoneNumber: '',
                region: '',
                region2: '',
                region3: '',
                address: '',
                scaleCount: '',
                registeredCapital: '',
                isEnable:true,
                wechatAppletID: '',
                wechatAppletHomePageUrl: '',
            },
            addLoading: false,
            listLoading: false,
            listLoading12: false,
            listLoading13: false,
            listLoading14: false,
            // 岗位信息数据
            dataTree:[],
            listLoading2: false,
            assignBtns: [],
            needViewsData1: [],
            needViewsData2: [],
            defaultProps: {
                children: "Children",
                label: "Remark",
                value:'Value',
            },
            formData1: {
                programIDs: '',
            },
            formData2: {
                businessLicenseImgUrl: '', //营业执照
                schoolLicenceImgUrl: '', //办学许可证
            },
            formData3: {
                receptionOneImgUrl: '', //前台外观照1
                receptionTwoImgUrl: '', //前台外观照2
            },
            formData4: {
                classRoomOneImgUrl: '', //理论学习场景1
                classRoomTwoImgUrl: '', //理论学习场景2
            },
            formData5: {
                operationOneImgUrl: '', //理论学习场景1
                operationTwoImgUrl: '', //理论学习场景2
            },
            // formData6: {
            //     lessonQRCodeImgUrl: '', //学习人口二维码
            // },
            formDataRules: {
                name: [{required: true, message: "请输入模板名称", trigger: "blur" }],
                organType: [{required: true, message: "请选择培训机构类型", trigger: "change" }],
                region: [{required: true, message: "请选择所在地区", trigger: "blur" }],
                contactPerson: [{required: true, message: "请输入联系人", trigger: "blur" }],
                contactPersonPhoneNumber: [
                    {required: true, message: "请输入联系人手机号", trigger: "blur" },
                    { validator: isPhoneNumberNum },
                ],
                address: [{required: true, message: "请输入详细地址", trigger: "blur" }],
                scaleCount: [{required: true, message: "请输入办学规模", trigger: "blur" }],
                registeredCapital: [{required: true, message: "请输入注册资本", trigger: "blur" }],
            },
            formDataRules1: {
                programIDs: [{required: true, message: "请选择岗位", trigger: "blur" }],
            },
            formDataRules2: {
                businessLicenseImgUrl: [{required: true, message: "请上传营业执照图片", trigger: "blur" }],
                schoolLicenceImgUrl: [{required: true, message: "请上传办学许可证图片", trigger: "blur" }],
            },
            formDataRules3: {
                receptionOneImgUrl: [{required: true, message: "请上传前台外观图片", trigger: "blur" }],
                receptionTwoImgUrl: [{required: true, message: "请上传前台外观二图片", trigger: "blur" }],
            },
            formDataRules4: {
                classRoomOneImgUrl: [{required: true, message: "请上传理论学习场景图片", trigger: "blur" }],
                classRoomTwoImgUrl: [{required: true, message: "请上传理论学习场景二图片", trigger: "blur" }],
            },
            formDataRules5: {
                operationOneImgUrl: [{required: true, message: "请上传实操学习场景图片", trigger: "blur" }],
                operationTwoImgUrl: [{required: true, message: "请上传实操学习场景二图片", trigger: "blur" }],
            },
            // formDataRules6: {
            //     lessonQRCodeImgUrl: [{required: true, message: "请上传学习人口二维码图片", trigger: "blur" }],
            // },
            shengArr: [],
            shiArr: [],
            xianArr: [],
            teaArr: [],
            canSubmits: true,
            canNextPage1: true,
            canNextPage2: true,

            // 资质图片参数
            canSubmit: 0,
            fileList1:[],
            isFirst: 1,

            businessLicenseImgUrl1_1: '', //营业执照数据
            businessLicenseImgUrl1_2: '',
            schoolLicenceImgUrl1_1: '', //办学许可证
            schoolLicenceImgUrl1_2: '',
            receptionOneImgUrl1_1: '', //前台外观照一
            receptionOneImgUrl1_2: '',
            receptionTwoImgUrl1_1: '', //前台外观照二
            receptionTwoImgUrl1_2: '',
            classRoomOneImgUrl1_1: '', //理论学习场景一
            classRoomOneImgUrl1_2: '',
            classRoomTwoImgUrl1_1: '', //理论学习场景二
            classRoomTwoImgUrl1_2: '',
            operationOneImgUrl1_1: '', //实操学习场景一
            operationOneImgUrl1_2: '',
            operationTwoImgUrl1_1: '', //实操学习场景二
            operationTwoImgUrl1_2: '',
            // lessonQRCodeImgUrl1_1: '', //学习人口二维码
            // lessonQRCodeImgUrl1_2: '',

        }
    },
    methods: {
        handleClick(tab, event) {
            // var _this = this
            // this.$refs.formData.validate(valid => {
            //     if (valid) {

            //     }else{
            //         this.$message({
            //             message: '请先填写必填项！',
            //             type: 'warning'
            //         });
            //         return false;
            //     }
            // })
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        // 图片上传之前函数
        beforeUpload(file) {
            if(file && this.isDisable == true){
                this.$message.warning('该机构已审核通过，不能修改了')
                return false
            }
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {} else {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传图片大小不能超过 2MB!');
                return false
            }
        },
        // 图片上传成功_营业执照
        handle_success1(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.businessLicenseImgUrl1_1 = res.Response
                this.businessLicenseImgUrl1_2 = res.Response.weburl
                this.formData2.businessLicenseImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_办学许可证
        handle_success2(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.schoolLicenceImgUrl1_1 = res.Response
                this.schoolLicenceImgUrl1_2 = res.Response.weburl
                this.formData2.schoolLicenceImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_前台外观照1
        handle_success3(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.receptionOneImgUrl1_1 = res.Response
                this.receptionOneImgUrl1_2 = res.Response.weburl
                this.formData3.receptionOneImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_前台外观照2
        handle_success4(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.receptionTwoImgUrl1_1 = res.Response
                this.receptionTwoImgUrl1_2 = res.Response.weburl
                this.formData3.receptionTwoImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_理论学习场景1
        handle_success5(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.classRoomOneImgUrl1_1 = res.Response
                this.classRoomOneImgUrl1_2 = res.Response.weburl
                this.formData4.classRoomOneImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_理论学习场景2
        handle_success6(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.classRoomTwoImgUrl1_1 = res.Response
                this.classRoomTwoImgUrl1_2 = res.Response.weburl
                this.formData4.classRoomTwoImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_实操学习场景1
        handle_success7(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.operationOneImgUrl1_1 = res.Response
                this.operationOneImgUrl1_2 = res.Response.weburl
                this.formData5.operationOneImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_实操学习场景2
        handle_success8(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.operationTwoImgUrl1_1 = res.Response
                this.operationTwoImgUrl1_2 = res.Response.weburl
                this.formData5.operationTwoImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        // 图片上传成功_学习人口二维码
        handle_success9(res) {
            if(res.Success) {
                this.$message.success('图片上传成功')
                this.lessonQRCodeImgUrl1_1 = res.Response
                this.lessonQRCodeImgUrl1_2 = res.Response.weburl
                this.formData6.lessonQRCodeImgUrl = 1
            }else{
                this.$message.error(res.Message)
            }
		},
        toSecondPage() {
            this.$refs.formData.validate(valid => {
                if (valid) {
                    this.canNextPage1 = false;
                    this.activeName = 'second'
                }
            })
        },
        toThirdPage() {
            var teaData = this.$refs.tree.getCheckedKeys();
            if(teaData.length > 0){
                this.formData1.programIDs = 1
            }else{
                this.formData1.programIDs = ''
            }
            this.$refs.formData1.validate(valid => {
                if (valid) {
                    if(teaData.length > 0){
                        this.teaArr = []
                        this.canSubmits = true
                        for(var a = 0; a < teaData.length; a++) {
                            if(this.assignBtns.length > 0) {
                                var newObj = {}
                                newObj.StationCode = teaData[a]
                                newObj.Online = false
                                newObj.OffLine = false
                                newObj.HandsOn = false
                                var isSelect = true
                                for(var b = 0; b < this.assignBtns.length; b++) {
                                    if(teaData[a] == this.assignBtns[b].split("-")[0]){
                                        isSelect = false
                                        if(this.assignBtns[b].split("-")[1] == 1){
                                            newObj.Online = true
                                        }
                                        if(this.assignBtns[b].split("-")[1] == 2){
                                            newObj.OffLine = true
                                        }
                                        if(this.assignBtns[b].split("-")[1] == 3){
                                            newObj.HandsOn = true
                                        }
                                    }
                                }
                                this.teaArr.push(newObj)
                                if(isSelect){
                                    this.$message({
                                        message: '请确保选择了对应岗位的学习方式！',
                                        type: 'warning'
                                    });
                                    this.canSubmits = false
                                    break;
                                }
                            }else{
                                this.$message({
                                    message: '请确保选择了对应岗位的学习方式！',
                                    type: 'warning'
                                });
                                this.canSubmits = false
                                break;
                            }
                        }
                        if(this.canSubmits){
                            this.canNextPage2 = false;
                            this.activeName = 'third'
                        }
                    }else{
                        this.$message({
                            message: '请选择岗位！',
                            type: 'warning'
                        });
                        return;
                    }
                }
            })
        },
        handleChange(val){
            var obj = {}
            obj = this.regionArr.find(function(item){
                return item.code === val
            })
            this.formData.region = obj.name
        },
        // 提交

        toSubmitData() {
            this.canSubmit = 0
            var teaData = this.$refs.tree.getCheckedNodes()
            if(teaData.length > 0){
                this.formData1.programIDs = 1
            }else{
                this.formData1.programIDs = ''
            }
            this.$refs.formData2.validate(valid => {
                if (valid) {
                    this.canSubmit++
                }
            })
            this.$refs.formData3.validate(valid => {
                if (valid) {
                    this.canSubmit++
                }
            })
            this.$refs.formData4.validate(valid => {
                if (valid) {
                    this.canSubmit++
                }
            })
            this.$refs.formData5.validate(valid => {
                if (valid) {
                    this.canSubmit++
                }
            })
            // this.$refs.formData6.validate(valid => {
            //     if (valid) {
            //         this.canSubmit++
            //     }
            // })
            this.$refs.formData.validate(valid => {
                if (valid) {
                    this.canSubmit++
                }
            })
            this.$refs.formData1.validate(valid => {
                if (valid) {
                    this.canSubmit++
                }
            })
            this.toThirdPage()
            if(this.canSubmits){

            }else{
                return
            }
            if(this.canSubmit === 6){
                var params = {
                    Name: this.formData.name,
                    TrainingMethod: this.formData.organType,
                    ContactPerson: this.formData.contactPerson,
                    ContactPersonPhoneNumber: this.formData.contactPersonPhoneNumber,
                    ProvinceId: this.formData.region,
                    CityId: this.formData.region2,
                    RegionId: this.formData.region3,
                    Address: this.formData.address,
                    ScaleCount: this.formData.scaleCount,
                    RegisteredCapital: this.formData.registeredCapital,
                    IsEnable:this.formData.isEnable,
                    ProgramList: this.teaArr
                }
                if(this.formData.organIntroduce){
                    params.Intro = this.formData.organIntroduce
                }
                if(this.formData.wechatAppletID){
                    params.WechatAppletId = this.formData.wechatAppletID
                }
                if(this.formData.wechatAppletHomePageUrl){
                    params.WechatAppletHomePageUrl = this.formData.wechatAppletHomePageUrl
                }
                if(this.businessLicenseImgUrl1_1.webupurl){
                    params.BusinessLicenseImgUrl = this.businessLicenseImgUrl1_1.webupurl
                }else{
                    params.BusinessLicenseImgUrl = this.businessLicenseImgUrl1_2
                }
                if(this.schoolLicenceImgUrl1_1.webupurl){
                    params.SchoolLicenceImgUrl = this.schoolLicenceImgUrl1_1.webupurl
                }else{
                    params.SchoolLicenceImgUrl = this.schoolLicenceImgUrl1_2
                }
                if(this.receptionOneImgUrl1_1.webupurl){
                    params.ReceptionOneImgUrl = this.receptionOneImgUrl1_1.webupurl
                }else{
                    params.ReceptionOneImgUrl = this.receptionOneImgUrl1_2
                }
                if(this.classRoomOneImgUrl1_1.webupurl){
                    params.ClassRoomOneImgUrl = this.classRoomOneImgUrl1_1.webupurl
                }else{
                    params.ClassRoomOneImgUrl = this.classRoomOneImgUrl1_2
                }
                if(this.operationOneImgUrl1_1.webupurl){
                    params.OperationOneImgUrl = this.operationOneImgUrl1_1.webupurl
                }else{
                    params.OperationOneImgUrl = this.operationOneImgUrl1_2
                }
                // if(this.lessonQRCodeImgUrl1_1.webupurl){
                //     params.LessonQRCodeImgUrl = this.lessonQRCodeImgUrl1_1.webupurl
                // }else{
                //     params.LessonQRCodeImgUrl = this.lessonQRCodeImgUrl1_2
                // }
                if(this.receptionTwoImgUrl1_1){
                    params.ReceptionTwoImgUrl = this.receptionTwoImgUrl1_1.webupurl
                }else{
                    params.ReceptionTwoImgUrl = this.receptionTwoImgUrl1_2
                }
                if(this.classRoomTwoImgUrl1_1){
                    params.ClassRoomTwoImgUrl = this.classRoomTwoImgUrl1_1.webupurl
                }else{
                    params.ClassRoomTwoImgUrl = this.classRoomTwoImgUrl1_2
                }
                if(this.operationTwoImgUrl1_1){
                    params.OperationTwoImgUrl = this.operationTwoImgUrl1_1.webupurl
                }else{
                    params.OperationTwoImgUrl = this.operationTwoImgUrl1_2
                }
                if(this.needSubmitID){
                    this.EditSubmit(params);
                }else{
                    this.addSubmit(params);
                }
            }else{
                this.$message({
                    message: '请检查是否已填写必填项',
                    type: 'warning'
                });
            }
        },
        addSubmit(params) {
            var _this = this
            this.addLoading = true
            addAgencyInfo(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.addLoading = false
                    // setTimeout(() => {
                    //     _this.$router.replace({
                    //         path: "/AgencyManager/ApproveAgency",
                    //     });
                    // },500)
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    setTimeout(() => {
                        _this.addLoading = false
                    },3000)
                }
            })
        },
        EditSubmit(params) {
            var _this = this
            params.Id = this.needSubmitID
            this.addLoading = true
            updateAgencyInfo(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.$message({
                        message: result.Message,
                        type: 'success'
                    });
                    this.addLoading = false
                    // setTimeout(() => {
                    //     _this.$router.replace({
                    //         path: "/AgencyManager/ApproveAgency",
                    //     });
                    // },500)
                }else{
                    this.$message({
                        message: result.Message,
                        type: 'error'
                    });
                    setTimeout(() => {
                        _this.addLoading = false
                    },3000)
                }
            })
        },
        // 获取培训机构详情
        getTrainingOrganizationDetail() {
            this.listLoading = true
            getAgencyInfo().then((res) => {
                var result = res.data
                if(result.Success){
                    this.isDisable == true
                    if(result.Response){
                        if(result.Response.Status == 1){
                            this.isDisable = true
                        }
                        this.needSubmitID = result.Response.Id
                        var resultData = result.Response
                        this.canNextPage1 = false;
                        this.canNextPage2 = false;
                        this.formData = {
                            name: resultData.Name,
                            organIntroduce: resultData.Intro,
                            contactPerson: resultData.ContactPerson,
                            contactPersonPhoneNumber: resultData.ContactPersonPhoneNumber,
                            address: resultData.Address,
                            scaleCount: resultData.ScaleCount,
                            registeredCapital: resultData.RegisteredCapital,
                            isEnable: resultData.IsEnable,
                            wechatAppletID: resultData.WechatAppletId,
                            wechatAppletHomePageUrl: resultData.WechatAppletHomePageUrl,
                        }
                        if(resultData.RegionId){
                            this.formData.region3 = Number(resultData.RegionId)
                        }
                        if(resultData.CityId){
                            this.formData.region2 = Number(resultData.CityId)
                        }
                        if(resultData.ProvinceId){
                            this.formData.region = Number(resultData.ProvinceId)
                        }

                        var params2 = {
                            parentId: resultData.ProvinceId,
                        }
                        var _this = this
                        setTimeout(() => {
                            _this.getCityPage2(params2);
                        },100)

                        var params3 = {
                            parentId: resultData.CityId,
                        }
                        setTimeout(() => {
                            _this.getCityPage3(params3);
                        },150)

                        this.needViewsData1 = []
                        this.needViewsData2 = []
                        result.Response.ProgramList.forEach(item => {
                            this.needViewsData1.push(item.StationId)
                            if(item.Online){
                                var a = item.StationId+'-'+1
                                this.needViewsData2.push(a.toString())
                            }
                            if(item.OffLine){
                                var a = item.StationId+'-'+2
                                this.needViewsData2.push(a.toString())
                            }
                            if(item.HandsOn){
                                var a = item.StationId+'-'+3
                                this.needViewsData2.push(a.toString())
                            }
                        })
                        // 资质照片相关数据
                        if(resultData.BusinessLicenseImgUrl){
                            this.formData2.businessLicenseImgUrl = 1
                            this.businessLicenseImgUrl1_2 = resultData.BusinessLicenseImgUrl
                        }
                        if(resultData.SchoolLicenceImgUrl){
                            this.formData2.schoolLicenceImgUrl = 1
                            this.schoolLicenceImgUrl1_2 = resultData.SchoolLicenceImgUrl
                        }
                        if(resultData.ReceptionOneImgUrl){
                            this.formData3.receptionOneImgUrl = 1
                            this.receptionOneImgUrl1_2 = resultData.ReceptionOneImgUrl
                        }
                        if(resultData.ReceptionTwoImgUrl){
                            this.formData3.receptionTwoImgUrl = 1
                            this.receptionTwoImgUrl1_2 = resultData.ReceptionTwoImgUrl
                        }
                        if(resultData.ClassRoomOneImgUrl){
                            this.formData4.classRoomOneImgUrl = 1
                            this.classRoomOneImgUrl1_2 = resultData.ClassRoomOneImgUrl
                        }
                        if(resultData.ClassRoomTwoImgUrl){
                            this.formData4.classRoomTwoImgUrl = 1
                            this.classRoomTwoImgUrl1_2 = resultData.ClassRoomTwoImgUrl
                        }
                        if(resultData.OperationOneImgUrl){
                            this.formData5.operationOneImgUrl = 1
                            this.operationOneImgUrl1_2 = resultData.OperationOneImgUrl
                        }
                        if(resultData.OperationTwoImgUrl){
                            this.formData5.operationTwoImgUrl = 1
                            this.operationTwoImgUrl1_2 = resultData.OperationTwoImgUrl
                        }
                        // if(resultData.LessonQRCodeImgUrl){
                        //     this.formData6.lessonQRCodeImgUrl = 1
                        //     this.lessonQRCodeImgUrl1_2 = resultData.LessonQRCodeImgUrl
                        // }
                    }

                    var that = this
                    // setTimeout(() => {
                        that.listLoading = false;
                    // },500)
                }
            })
        },
        // 获取岗位信息
        getDictionaryList() {
            this.listLoading2 = true
            var params = {
                namespace: 'BizService.ExamService.StationCode',
            }
            getDeletedStaffsAuthoritySetting(params).then((res) => {
                var result = res.data
                if(result.Success){
                    result.Response.forEach(item => {
                        item.Buttons = [{
                            ID:item.Value+'-'+1,
                            IsHide:false,
                            Name:'理论线上学习'
                        },{
                            ID:item.Value+'-'+2,
                            IsHide:false,
                            Name:'理论线下面授'
                        },{
                            ID:item.Value+'-'+3,
                            IsHide:false,
                            Name:'实操面授'
                        }]
                    });
                    this.dataTree = result.Response
	                console.log(result.Response,'resssssssss')
                    if(this.isDisable == true){
                        this.dataTree.forEach(item => {
                            item.disabled = true
                        })
                        this.$refs.tree.setCheckedKeys(this.dataTree)
                    }
                    this.$refs.tree.setCheckedKeys(this.needViewsData1);
                    this.assignBtns = this.needViewsData2
                    this.listLoading2 = false;
                }else{
                    this.listLoading2 = false;
                }
            })
        },
        // 省选中
        selectRowChange1(row) {
            this.parentSheng = row
	        console.log(row,'row')
            var params = {
                parentId: this.parentSheng,
            }
            this.getCityPage2(params);
            this.formData.region2 = ''
            this.formData.region3 = ''
        },
        // 市选中
        selectRowChange2(row) {
            this.$forceUpdate()
            this.parentShi = row
            var params = {
                parentId: this.parentShi,
            }
            this.getCityPage3(params);
            this.formData.region3 = ''
        },
        selectRowChange3() {
            this.$forceUpdate()
        },
        // 获取行政区域
        getCityPage(params) {
            this.listLoading12 = true
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.shengArr = result.Response.reverse()
                    this.listLoading12 = false
                }
            })
        },
        getCityPage2(params) {
            this.listLoading13 = true
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.shiArr = result.Response.reverse()
                    this.listLoading13 = false
                }
            })
        },
        getCityPage3(params) {
            this.listLoading14 = true
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.xianArr = result.Response.reverse()
                    this.listLoading14 = false
                }
            })
        },
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    created() {
        var _this = this
        var params = {
            level: 0,
        }
        this.uploadImagesUrl = api.uploadImagesUrl
        this.getTrainingOrganizationDetail();
        this.getCityPage(params);
        setTimeout(() => {
            _this.getDictionaryList();
        },1000)
    },
    mounted() {
        // if(city){
        //     this.regionArr = city
        // }
    }
}
</script>

<style lang="stylus" scoped>
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
    .selecRegionStyle {
        font-size 14px;
        color #606266
        margin 0 15px;
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
